@media only screen and (max-width: 600px) {
	.card {
		height: 200px;
	}

	.container {
		padding-top: 50px;
	}
	.logo {
		align-items: flex-start;
	}
	.logo img {
		margin-top: 10px;
		margin-right: 10px;
		height: 80px;
	}

	.top h3 {
		width: 65%;
		font-size: 16px;
		line-height: 18px;
	}

	.top h4 {
		width: 65%;
		font-size: 12px;
		line-height: 14px;
	}

	.bottom h3 {
		font-size: 14px;
		margin-bottom: 3px;
		line-height: 18px;
		width: 100%;
	}

	.bottom p {
		font-size: 12px;
		margin-bottom: 3px;
		line-height: 14px;
		width: 100%;
	}
}
